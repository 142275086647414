import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { SocketEvents } from "../utils/enums";

interface IWebSocketContext {
  sendMessage: (message: any) => void;
  oddsData: any;
  fancyData: any;
  bookmakerData: any;
}

const WebSocketContext = createContext<IWebSocketContext | undefined>(
  undefined
);

export const WebSocketContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const socket = useRef<WebSocket | null>(null);
  const [oddsData, setOddsData] = useState<any>(null);
  const [fancyData, setFancy] = useState<any>(null);
  const [bookmakerData, setBookmaker] = useState<any>(null);
  const reconnectAttempts = useRef<number>(0);
  const reconnectTimeout = useRef<number | null>(null);
  const lastMessage = useRef<any>(null); // Store the last message

  const sendMessage = useCallback((message: any[]) => {
    lastMessage.current = message; // Store the message before sending it
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(message));
    }
  }, []);

  const connect = useCallback(() => {
    let url: string = process.env.REACT_APP_SOCKET_URL ?? "ws://fallback-url";
    socket.current = new WebSocket(url);
    socket.current.addEventListener("open", () => {
      console.log("WebSocket connection opened");
      reconnectAttempts.current = 0; // Reset reconnection attempts on successful connection
      // Resend the last message after reconnecting
      if (lastMessage.current) {
        sendMessage(lastMessage.current);
      }
    });

    socket.current.addEventListener("message", (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      if (data.data) {
        switch (data.event) {
          case SocketEvents.MARKET_ODDS_RESPONSE:
            setOddsData(data.data);
            break;
          case SocketEvents.FENCY_ODDS_RESPONSE:
            setFancy(data.data.data);
            break;
          case SocketEvents.BOOKMAKER_ODDS_RESPONSE:
            setBookmaker(data.data.data);
            break;
        }
      }
    });

    socket.current.addEventListener("close", () => {
      console.log("WebSocket connection closed, attempting reconnect...");
      if (reconnectAttempts.current < 10) {
        const timeout = Math.min(1000 * 2 ** reconnectAttempts.current, 30000); // Exponential backoff
        reconnectTimeout.current = window.setTimeout(connect, timeout);
        reconnectAttempts.current += 1;
      }
    });

    socket.current.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
      socket.current?.close();
    });
  }, [sendMessage]);

  useEffect(() => {
    connect();
    return () => {
      if (socket.current) {
        socket.current.close();
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, [connect]);

  return (
    <WebSocketContext.Provider
      value={{ sendMessage, oddsData, fancyData, bookmakerData }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
