import { ICommonOptions } from "../interfaces/interfaceCommon";

export const Payment = {
    RETURNID: "returnId"
}

export const OwnerRoutes = {
    routeDashboard: "/",
    routeLogin: "/login",
    routeUserProfile: "/profile",
    routeAddSubowner: "/addsubowner",
    routeWhiteLabelList: "/whitelabellist",
    routeEditWhiteLabel: "/whitelabellist/:whitelabel",
    routeAdminRole: "/adminrolemanagement",
    routePaymentTransfer: "/paymenttransfer",
    permissionList: "/permissionlist",
    routeTransaction: "/transaction",
    routeFeatureControl: "/featurecontrol",
    routeSettlement: "/settlement",
    // routeCustomers: "/customers",
    // routeEditCustomer: "/customers/:customerId",
};

/** These names needs to be same as registered modules names in DB */
export const OwnerModuleNames = {
    DASHBOARD: "Dashboard",
    ADD_SUBOWNER: "Add Subowner",
    WHITELABEL_LIST: "White Label List",
    ADMIN_ROLE: "W/L Admin Role Module",
    ACCOUNT_STATEMENT: "Account Statements",
    PAYMENT_METHODS: "Payment Methods",
    FEATURE_CONTROL: "Handle Feature",
    SETTLEMENT_FEATURE: "Settlement",
    PROFILE: "Profile",
    MINT: "Mint",
    DEPOSIT_WITHDRAW: "Deposit/Withdraw" //It is used to show hide Actions & config buttons in W/L list
};

/** Routes of Whitelabel project */
export const WhitelabelRoutes = {
    routeDashboard: "/",
    routeLogin: "/login",
    routeUserProfile: "/profile",
    routePaymentMethod: "/paymentmethod",
    routeWhiteLabelUserList: "/whitelabeluserlist",
    routeCreateSublevel: "/createsublevel",
    depositeWithdrawPanel: "/paneluser",
    depositeWithdrawRoleManagement: "/rolemanagment",
    routeTransaction: "/transaction",
    routeBankDetails: "/bankdetails",
    routeGlobalSearch: "/globalsearch",
    routesSubUser: "/subuser",
    routesSubUserRolePanel: "/subuserrolepanel",
    routesSettlement: "/settlement",
    routesPLReports: "/plreports",
    routesBetTicker: "/betticker",
    routesRunningAnalysis: "/runninganalysis",
    routesMarketAnalysis: "/marketanalysis"
};

/** These names needs to be same as registered modules names in DB */
export const WhitelabelModuleNames = {
    DASHBOARD: "Dashboard",
    GLOBAL_SEARCH: "Global Search",
    SUBUSER_LISTING: "Subuser Listing",
    SUBUSER_ROLE_PANEL: "Subuser Role Panel",
    SUBLEVEL_LISTING: "Sublevel Listing",
    SUBLEVEL_CREATION: "Create Sublevel",
    PANEL_ROLE_MODULE: "Panel Role Module",
    PANEL_USER_LIST: "Panel User List",
    TRANSACTION: "Transaction",
    PAYMENT_METHOD: "Payment Method",
    BANK_DETAILS: "Bank Details",
    PROFILE: "Profile",
    SETTLEMENT: "Settlement",
    CLIENT_PL: "Profit/Loss",
    BET_TICKER: "Bet Ticker",
    RUNNING_ANALYSIS: "Running Analysis",
    MARKET_ANALYSIS: "Market Analysis",
};

// List to set all modules by default to Owner make sure whenever a new module is added its added to this list as well to show by default to owner
export const OWNER_MODULE_LIST = [
	{
		id: 13,
		name: OwnerModuleNames.FEATURE_CONTROL
	},
	{
		id: 11,
		name: OwnerModuleNames.ACCOUNT_STATEMENT
	},
	{
		id: 10,
		name: OwnerModuleNames.ADMIN_ROLE
	},
	{
		id: 21,
		name: OwnerModuleNames.ADD_SUBOWNER
	},
	{
		id: 12,
		name: OwnerModuleNames.PAYMENT_METHODS
	},
	{
		id: 14,
		name: OwnerModuleNames.WHITELABEL_LIST
	},
    {
		id: 15,
		name: OwnerModuleNames.MINT
	},
    {
		id: 16,
		name: OwnerModuleNames.DEPOSIT_WITHDRAW
	},
    {
		id: 20,
		name: OwnerModuleNames.SETTLEMENT_FEATURE
	},
];

/** All Roles */
export const UserRole = {
    SOFTWARE: "SOFTWARE",
    CHECKER: "CHECKER",
    PILOT: "PILOT",
    WHITELABEL: "WHITELABEL",
    ADMIN: "ADMIN",
    SUPER: "SUPER",
    MASTER: "MASTER",
    AGENT: "AGENT",
    PLAYER: "PLAYER",
    SUBUSER: "SUBUSER"
};

/** WL role static */
export const roleaArr_WL = [
    { id: 1, name: "WHITELABEL" },
    { id: 2, name: "ADMIN" },
    { id: 3, name: "SUPER" },
    { id: 4, name: "MASTER" },
    { id: 5, name: "AGENT" },
    { id: 6, name: "PLAYER" },
    { id: 7, name: "SUBUSER" }
];

/** All Privilages */
export const UserPrivilages = {
    VIEW: "View",
    EDIT: "Edit",
    DELETE: "Delete",
    ADD: "Add",
};

/** Privilages */
export const priviligeOptions: ICommonOptions[] = [
    { id: 1, name: 'View', },
    { id: 2, name: 'Edit' },
    { id: 3, name: 'Delete' },
    { id: 4, name: 'Add' },
];

export const WLpriviligeOptions: ICommonOptions[] = [
    { id: 1, name: 'View', },
    { id: 2, name: 'Add' },
];

/** WhiteLabel Type */
export const WhitelabelType = {
    B2B: "B2B",
    B2C: "B2C"
};

/** All Providers */
export const GamesProviders = {
    GAP: "GAP",
    QTECH: "QTECH",
    PREMIUMODDS: "PREMIUMODDS",
    SPORTSBOOK: "SPORTSBOOK",
};

/** Wallet type */
export const walletTypeOptions: ICommonOptions[] = [
    { id: 1, name: 'Cash', },
    { id: 2, name: 'Credit' },
];

export const PaginationOptions = [5, 10, 15];

export const MarketTypes = {
    FANCY: "FANCY",
    LINE: "LINE",
    MATCH_ODDS: "MATCH_ODDS",
    BOOKMAKER: "BOOKMAKER",
    MINI_BOOKMAKER: "MINI_BOOKMAKER",
    EXTRA_BOOKMAKER: "EXTRA_BOOKMAKER",
    TIED_MATCH: "TIED_MATCH",
}

export const MarketAnalysisBtns = {
    BET: "BET LOCK",
    BOOK: "BOOK",
    MY_BETS: "MY BETS"
}

export const MarketStatus = [
    { id: 1, text: "Open" },
    { id: 2, text: "In Active" },
    { id: 3, text: "Suspended" },
    { id: 4, text: "Closed" },
    { id: 9, text: "Ball Start" },
];

export const SocketEvents = {
    MARKET_ODDS_RESPONSE: "marketOddsData",
    FENCY_ODDS_RESPONSE: "marketOddsData_Fancy",
    BOOKMAKER_ODDS_RESPONSE: "marketOddsData_BM",
    CLOSE_MARKET_ODDS: "closeMarketOdds",
    CLOSE_MARKET_FENCY: "unsubscribeFancyMarketOdds",
    GET_MARKET_ODDS: "getMarketOdds",
    GET_FENCY_ODDS: "getFancyMarketOdds",
    GET_BOOKMAKER_ODDS: "getBMMarketOdds"
};